import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
// import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function RescueMe() {
  return (
    <Layout>
      <Helmet title="Rescue Me | Michael Weslander" />

      <h1>Rescue Me</h1>
      <h2>Michael Weslander</h2>
      <h3>Key: Am</h3>
      <h4>Capo: 2</h4>
      <h4>Tempo: 92</h4>
      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>Every now and then</p>
          <p>The lights will flicker green</p>
          <p>For now the yellow</p>
          <p>Covers up the scene</p>
        </Verse>

        <Verse>
          <p>As brake lights flash</p>
          <p>With such useless ease</p>
          <p>My motivation</p>
          <p>Takes another leave</p>
        </Verse>

        <Chorus>
          <p>Oh sleep</p>
          <p>Is my disease</p>
          <p>As another night</p>
          <p>Will calm the stormy seas</p>
          <p>I will wonder</p>
          <p>Where you are</p>
          <p>And why the hell</p>
          <p>You can't just come and</p>
          <p>Rescue Me</p>
        </Chorus>

        <Verse>
          <p>Every now and then again</p>
          <p>I'll meet you in my dreams</p>
          <p>But dreams are built on</p>
          <p>Grains of shifting sand</p>
        </Verse>

        <Verse>
          <p>Oh it's hard not to rely on</p>
          <p>Everything I feel</p>
          <p>When this feels as real</p>
          <p>As the palms of my hands</p>
        </Verse>

        <Chorus>
          <p>This time dreams</p>
          <p>Are my disease</p>
          <p>As another night</p>
          <p>Will calm the stormy seas</p>
          <p>I will wonder</p>
          <p>Where you are</p>
          <p>And why the hell</p>
          <p>You can't just come and</p>
          <p>Rescue Me</p>
        </Chorus>

        <Bridge>
          <p>Am</p>
        </Bridge>

        <Verse>
          <p>Every now and then I fear</p>
          <p>I'll never make it through</p>
          <p>But life they say</p>
          <p>Is all about the ride</p>
        </Verse>

        <Verse>
          <p>As the concentration flows</p>
          <p>From my brain down to my toes</p>
          <p>I wonder if they'll</p>
          <p>Take me to your side</p>
        </Verse>

        <Chorus>
          <p>This time haste</p>
          <p>Are my disease</p>
          <p>As another night</p>
          <p>Will calm the stormy seas</p>
          <p>I will wonder</p>
          <p>Where you are</p>
          <p>And why the hell</p>
          <p>You can't just come and</p>
          <p>Rescue Me</p>
        </Chorus>

        <Verse>
          <p>Every now and then again</p>
          <p>The clock will flicker 3</p>
          <p>Hours pass with nothing</p>
          <p>New for me</p>
        </Verse>

        <Verse>
          <p>As I lay down for the night</p>
          <p>I know the morn will be a fight</p>
          <p>To wake these days</p>
          <p>Just ain't quite the same as it used to be</p>
        </Verse>
      </Columns>
    </Layout>
  );
}



